import React from 'react'
import Search from '../reservation/search'

type Props = {}



const MyTicket = (props: Props) => {
    
  return (
    <Search/>
  )
}

export default MyTicket