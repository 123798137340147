import React from 'react'

type Props = {}

const Footer = (props: Props) => {
  return (
    <div className='h-140px'>
                {/* 모든 정보를 보여주기 위한 마진 */}
    </div>
  )
}

export default Footer